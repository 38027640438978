import { getEnvironment } from '../../env.utils';

import { AUTH_STORAGE_KEY } from './AuthSync.constants';
import { AuthSyncMessage } from './AuthSync.schemas';

/**
 * Get the domain for the cookie. The cookie is shared between Notion and Mail.
 */
export const getBaseDomain = () => {
  // For localhost, return .localhost
  const isLocalhost = getEnvironment(window.location.origin) === 'local';
  if (isLocalhost) {
    return '.localhost';
  }

  // For other environments, get the base domain
  // e.g., from mail.dev.notion.so -> .dev.notion.so
  //       from mail.notion.so -> .notion.so
  const parts = window.location.hostname.split('.');
  if (parts.length > 2) {
    return '.' + parts.slice(-3).join('.');
  }
  return '.' + parts.slice(-2).join('.');
};

export const getCookieKey = (messageName: string) => `${AUTH_STORAGE_KEY}_${messageName}`;

/**
 * Set the cookie for the message. The cookie is shared between Notion and Mail.
 * The cookie key includes the message name to ensure uniqueness.
 * The cookie value is the message we want to broadcast to other windows.
 * If persistCookie is true, the cookie will not expire. Otherwise, it will expire in 5 seconds.
 */
export const setCookie = ({
  message,
  messageID,
  origin,
  persistCookie
}: {
  message: AuthSyncMessage;
  origin: string;
  messageID: string;
  persistCookie: boolean;
}) => {
  const domain = getBaseDomain();
  const isLocalhost = getEnvironment(window.location.origin) === 'local';
  document.cookie = `${getCookieKey(message.name)}=${encodeURIComponent(
    JSON.stringify({
      message,
      origin,
      messageID // Include ID for deduplication
    })
  )}; path=/; domain=${domain};${persistCookie ? '' : ' max-age=5;'}${isLocalhost ? '' : ' Secure;'} SameSite=Lax`;
};

export const getCurrentAuthSyncCookies = () => {
  const cookies = document.cookie.split(';');
  return cookies.filter((c) => c.trim().startsWith(`${AUTH_STORAGE_KEY}_`));
};

/**
 * Normalize the cookies by sorting them and joining them with a comma.
 * This is used to compare the cookie values.
 */
export const normalizeCookies = (cookies: string[]) => {
  return cookies.sort().join(',');
};
