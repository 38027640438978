import React, { useEffect, useRef } from 'react';
import isUUID from 'validator/lib/isUUID';

import { getMailDomainBaseURLs, getNotionDomainBaseURL } from '../../env.utils';

import { AUTH_CHANNEL, INITIAL_HANDSHAKE, NOTION_USER_ID_KEY, AUTH_SYNC_VERSION } from './AuthSync.constants';
import {
  AuthSyncMessage,
  broadcastMessageSchema,
  cookieMessageSchema,
  getNotionUserIDMessageSchema,
  setNotionUserIDMessageSchema,
  type BroadcastMessage,
  type NotionUserIDMessage
} from './AuthSync.schemas';
import { getCurrentAuthSyncCookies, normalizeCookies, setCookie } from './AuthSync.utils';

const DEBUG = false; // Toggle this to control logging

const debugLog = (message: string, ...args: unknown[]) => {
  if (DEBUG) {
    console.log(`${new Date().toLocaleTimeString('en-US', { hour12: false })} [AuthSync:IDENTITY] ${message}`, ...args);
  }
};

const COOKIE_POLLING_INTERVAL = 1000;

/**
 * AuthSync enables cross-origin communication between different web apps (e.g., Mail and Notion).
 * Each app loads this component in an iframe from the Identity domain.
 * Messages can either be general broadcast messages or query/mutation messages.
 *
 * Communication flow:
 * 1. Initial setup:
 *    - Parent window establishes MessageChannel connection with iframe
 *    - Iframe tests BroadcastChannel by sending 'test' message
 *    - Other clients respond with 'test_ack'
 *
 * If we are broadcasting messages:
 * 2. Primary communication (BroadcastChannel):
 *    - If test_ack received, BroadcastChannel is working
 *    - Messages are broadcast to all clients
 *    - Only messages from different origins are forwarded to parent
 *
 * 3. Fallback communication (Cookies):
 *    - If no test_ack received, fall back to cookie polling
 *    - Messages are written to domain-wide cookies with unique message names
 *    - Clients poll for cookie changes every COOKIE_POLLING_INTERVAL ms
 *    - Each tab tracks which cookies it has processed to prevent double-processing
 *
 * 4. Dynamic adaptation:
 *    - When new client connects, sends test message
 *    - Existing polling clients can switch to BroadcastChannel
 *    - Cookie polling stops once BroadcastChannel confirmed working
 *
 * If we are not broadcasting messages:
 * 5. Query/Mutation handling:
 *    - Queries (e.g., getNotionUserID) request data from Identity
 *    - Mutations (e.g., setNotionUserID) update data in Identity
 *    - These messages are handled directly without broadcasting
 *    - Used for syncing state between apps and Identity
 */

export const AuthSync: React.FC = () => {
  // General broadcast channel for messages
  const bc = new BroadcastChannel(AUTH_CHANNEL);
  const messageChannel = new MessageChannel();
  const parentOriginRef = useRef('');
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const broadcastWorkingRef = useRef(false);
  // Track which cookies we've processed to avoid double-processing in a single tab
  const processedCookieIDsRef = useRef(new Set<string>());

  const postMessageToBroadcastChannel = (data: {
    message: AuthSyncMessage | undefined;
    type: 'broadcast' | 'test' | 'test_ack';
    id: string;
    origin: string;
  }) => {
    bc.postMessage(data);
  };

  const processCookies = (authSyncCookies: string[], oldAuthSyncCookies: string[] | undefined) => {
    debugLog('Processing Auth Sync Cookies:', {
      authSyncCookies
    });

    // Process each cookie that has a value
    for (const cookie of authSyncCookies) {
      try {
        const [key, encodedValue] = cookie.split('=');
        if (!key || !encodedValue) continue;

        // Find matching old cookie if it exists
        const oldCookie = oldAuthSyncCookies?.find((c) => c.startsWith(`${key}=`));
        const oldValue = oldCookie?.split('=')[1];

        // Skip if value hasn't changed
        if (oldValue === encodedValue) {
          debugLog('Skipping cookie, nothing changed:', key);
          continue;
        }

        const value = decodeURIComponent(encodedValue);
        const parsedValue = JSON.parse(value) as unknown;

        // Validate cookie message structure
        const result = cookieMessageSchema.safeParse(parsedValue);
        if (!result.success) {
          console.error('[AuthSync:IDENTITY] Invalid cookie message structure:', result.error);
          continue;
        }

        const { message, messageID, origin } = result.data;

        // Skip if we've already processed this cookie message in this tab
        if (processedCookieIDsRef.current.has(messageID)) {
          debugLog('Skipping already processed message:', messageID);
          continue;
        }

        // Only post the message if the sending origin is different from the parent origin
        // I.e. If Mail sends a message, don't post it back to Mail
        if (origin !== parentOriginRef.current) {
          debugLog('Posting message to port', { messageID, message, origin });
          messageChannel.port1.postMessage(message);
          // Mark cookie as processed in this tab to avoid double-processing
          processedCookieIDsRef.current.add(messageID);
        }
      } catch (error) {
        console.error('[AuthSync:IDENTITY] Failed to parse cookie:', error);
      }
    }
  };

  const startPollingForCookieUpdates = () => {
    if (pollingIntervalRef.current) return;

    let lastAuthSyncCookies = getCurrentAuthSyncCookies();
    pollingIntervalRef.current = setInterval(() => {
      const currentAuthSyncCookies = getCurrentAuthSyncCookies();
      if (normalizeCookies(currentAuthSyncCookies) !== normalizeCookies(lastAuthSyncCookies)) {
        processCookies(currentAuthSyncCookies, lastAuthSyncCookies);
      }
      lastAuthSyncCookies = currentAuthSyncCookies;
    }, COOKIE_POLLING_INTERVAL);
  };

  // Test the broadcast channel when component mounts
  // If it does not work, such as on Safari, we fall back to
  // polling for cookie updates in order to detect messages
  const testBroadcastChannel = () => {
    const testID = crypto.randomUUID();
    debugLog('Testing broadcast channel...', parentOriginRef.current);

    postMessageToBroadcastChannel({
      type: 'test',
      id: testID,
      origin: parentOriginRef.current,
      message: undefined
    });

    setTimeout(() => {
      if (!broadcastWorkingRef.current) {
        debugLog('Broadcast channel not working, starting cookie polling');
        startPollingForCookieUpdates();
      }
    }, 100);
  };

  // Handle the initial handshake from the parent window
  const handleInitialHandshake = (event: MessageEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data, origin } = event;

    if (!getMailDomainBaseURLs().includes(origin) && origin !== getNotionDomainBaseURL()) {
      return;
    }

    if (data === INITIAL_HANDSHAKE) {
      // Store the parent's origin when we establish connection
      parentOriginRef.current = origin;
      debugLog('Connected to parent:', origin);

      window.parent.postMessage({ channel: AUTH_CHANNEL }, origin, [messageChannel.port2]);
      window.removeEventListener('message', handleInitialHandshake);

      // Test broadcast channel immediately after handshake
      testBroadcastChannel();

      // Process any existing cookies
      debugLog('Checking existing cookies during handshake');
      processCookies(getCurrentAuthSyncCookies(), undefined);
    }
  };
  window.addEventListener('message', handleInitialHandshake);

  // Handle messages from the broadcast channel
  bc.onmessage = (event: MessageEvent<BroadcastMessage>) => {
    const result = broadcastMessageSchema.safeParse(event.data);
    if (!result.success) {
      console.error('[AuthSync:IDENTITY] Invalid broadcast message structure:', result.error);
      return;
    }

    const { message, origin, type } = result.data;

    // If the origin is the same as the parent origin, we don't need to process the message
    if (origin === parentOriginRef.current) {
      return;
    }

    debugLog('Received broadcast message:', {
      type,
      message,
      origin,
      parentOriginRef: parentOriginRef.current
    });

    // Acknowledge that the broadcast channel is working
    // and if we are polling, stop polling
    const acknowledgeBroadcastChannel = () => {
      // If we have already acknowledged that the broadcast channel is working,
      // and we are not polling for cookie updates, don't acknowledge again!
      if (broadcastWorkingRef.current && !pollingIntervalRef.current) return;
      broadcastWorkingRef.current = true;
      debugLog('Broadcast channel is working, received ack from:', origin);
      if (pollingIntervalRef.current) {
        debugLog('Broadcast channel is working, stopping cookie polling');
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = null;
      }
    };

    const isPollingForCookieUpdates = !!pollingIntervalRef.current;

    if (type === 'test') {
      debugLog('Sending test_ack to broadcast channel', {
        currentOrigin: parentOriginRef.current,
        isPolling: isPollingForCookieUpdates
      });
      // Post a test_ack to the sender of the test message
      // This lets the sender know that the broadcast channel is working
      postMessageToBroadcastChannel({
        type: 'test_ack',
        id: event.data.id,
        origin: parentOriginRef.current,
        message: undefined
      });

      // Also acknowledge that the broadcast channel is working
      // in case we were polling for cookie updates before
      // This lets existing clients know broadcast now works
      // For instance, if you first open Mail without having opened Notion,
      // the Mail instance will start polling for cookie updates.
      // Then when Notion is opened, Mail will need to acknowledge that the
      // broadcast channel is working and stop polling for cookie updates
      acknowledgeBroadcastChannel();
    } else if (type === 'test_ack') {
      acknowledgeBroadcastChannel();
    } else if (type === 'broadcast' && origin !== parentOriginRef.current) {
      messageChannel.port1.postMessage(message);
    }
  };

  const handleQueryMessage = (event: MessageEvent<AuthSyncMessage>) => {
    debugLog('Received query message:', event.data);
    if (event.data.name === 'getNotionUserID') {
      const result = getNotionUserIDMessageSchema.safeParse(event.data);
      if (!result.success) {
        console.error('[AuthSync:IDENTITY] Invalid getNotionUserID message structure:', result.error);
        return;
      }

      const notionUserID = localStorage.getItem(NOTION_USER_ID_KEY);
      const response: NotionUserIDMessage = {
        name: 'notionUserID',
        notionUserID: notionUserID && isUUID(notionUserID) ? notionUserID : '',
        version: AUTH_SYNC_VERSION,
        source: 'identity'
      };
      messageChannel.port1.postMessage(response);
    }
  };

  const handleMutationMessage = (event: MessageEvent<AuthSyncMessage>) => {
    debugLog('Received mutation message:', event.data);
    if (event.data.name === 'setNotionUserID') {
      const result = setNotionUserIDMessageSchema.safeParse(event.data);
      if (!result.success) {
        debugLog('Invalid setNotionUserID message structure:', event.data);
        console.error('[AuthSync:IDENTITY] Invalid setNotionUserID message structure:', result.error);
        return;
      }

      const { notionUserID } = result.data;
      if (!isUUID(notionUserID) && notionUserID !== '') {
        return;
      }
      if (notionUserID) {
        localStorage.setItem(NOTION_USER_ID_KEY, notionUserID);
      } else if (notionUserID === '') {
        localStorage.removeItem(NOTION_USER_ID_KEY);
      }
    }
  };

  // Handle messages from Notion/Mail/clients connected to Identity
  messageChannel.port1.onmessage = (event: MessageEvent<AuthSyncMessage>) => {
    const { persistCookie, type } = event.data;
    if (type === 'query') {
      handleQueryMessage(event);
    } else if (type === 'mutation') {
      handleMutationMessage(event);
    } else {
      const messageID = crypto.randomUUID();
      // Only use broadcast if we've confirmed it's working
      if (broadcastWorkingRef.current) {
        debugLog('Broadcasting message:', messageID, event.data);
        postMessageToBroadcastChannel({
          type: 'broadcast',
          message: event.data,
          id: messageID,
          origin: parentOriginRef.current
        });
        // If it is a persistent message, always set the cookie
        // This ensures that the last message is always broadcasted, even if
        // the other clients are not listening at the moment.
        if (persistCookie) {
          setCookie({
            message: event.data,
            origin: parentOriginRef.current,
            messageID,
            persistCookie: true
          });
        }
      }
      // Only use cookies if broadcast channel isn't working
      else {
        debugLog('Using cookie fallback for message:', messageID, event.data);
        setCookie({
          message: event.data,
          origin: parentOriginRef.current,
          messageID,
          persistCookie: !!persistCookie
        });
      }
    }
  };

  // Clean up when component unmounts
  useEffect(() => {
    return () => {
      debugLog('Cleaning up AuthSync');
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
      }
      bc.close();
      messageChannel.port1.close();
      messageChannel.port2.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
