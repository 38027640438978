import { z } from 'zod';

// Base message schema that all messages must conform to
export const baseMessageSchema = z.object({
  name: z.string(),
  version: z.number(),
  source: z.string(),
  persistCookie: z.boolean().optional()
});

// Schema for NotionUserID messages
export const notionUserIDMessageSchema = baseMessageSchema.extend({
  name: z.literal('notionUserID'),
  notionUserID: z.string()
});

// Schema for setNotionUserID messages
export const setNotionUserIDMessageSchema = baseMessageSchema.extend({
  name: z.literal('setNotionUserID'),
  notionUserID: z.string(),
  type: z.literal('mutation')
});

// Schema for getNotionUserID messages
export const getNotionUserIDMessageSchema = baseMessageSchema.extend({
  name: z.literal('getNotionUserID'),
  type: z.literal('query')
});

// Schema for mailLoaded messages
export const mailLoadedMessageSchema = baseMessageSchema.extend({
  name: z.literal('mailLoaded')
});

// Schema for unreadCount messages
export const unreadCountMessageSchema = baseMessageSchema.extend({
  name: z.literal('unreadCount'),
  unreadCount: z.number()
});

// Schema for account switcher messages
const accountSwitcherMessageSchema = baseMessageSchema.extend({
  name: z.literal('accountSwitcher'),
  accounts: z.array(
    z.object({
      id: z.string(),
      displayName: z.string(),
      emailAddress: z.string()
    })
  )
});

// Schema for broadcast messages
export const broadcastMessageSchema = z.object({
  message: z
    .union([
      notionUserIDMessageSchema,
      setNotionUserIDMessageSchema,
      getNotionUserIDMessageSchema,
      accountSwitcherMessageSchema,
      mailLoadedMessageSchema,
      unreadCountMessageSchema
    ])
    .optional(),
  type: z.enum(['broadcast', 'test', 'test_ack']),
  id: z.string(),
  origin: z.string()
});

// Schema for cookie messages
export const cookieMessageSchema = z.object({
  message: z.union([
    notionUserIDMessageSchema,
    setNotionUserIDMessageSchema,
    getNotionUserIDMessageSchema,
    accountSwitcherMessageSchema,
    mailLoadedMessageSchema,
    unreadCountMessageSchema
  ]),
  origin: z.string(),
  messageID: z.string()
});

export const authSyncMessageSchema = baseMessageSchema.and(
  z.union([setNotionUserIDMessageSchema, getNotionUserIDMessageSchema])
);

// Type exports
export type BaseMessage = z.infer<typeof baseMessageSchema>;
export type NotionUserIDMessage = z.infer<typeof notionUserIDMessageSchema>;
export type SetNotionUserIDMessage = z.infer<typeof setNotionUserIDMessageSchema>;
export type GetNotionUserIDMessage = z.infer<typeof getNotionUserIDMessageSchema>;
export type BroadcastMessage = z.infer<typeof broadcastMessageSchema>;
export type CookieMessage = z.infer<typeof cookieMessageSchema>;
export type MailLoadedMessage = z.infer<typeof mailLoadedMessageSchema>;
export type UnreadCountMessage = z.infer<typeof unreadCountMessageSchema>;
export type AuthSyncMessage = z.infer<typeof authSyncMessageSchema>;
